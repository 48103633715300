import $ from "jquery";

import imagesLoaded from "imagesloaded";
import Isotope from "isotope-layout";


// var $container = $('#grid');
// $container.imagesLoaded( function(){
//     $container.isotope({
//         itemSelector: '.grid-item',
//         isFitWidth: true,
//         percentPosition: true,
//         masonry: {
//             columnWidth: '.grid-sizer',
//             gutter: 0
//         }
//     });
// });


if ($("#grid").length) {
    imagesLoaded("#grid", function () {
        var iso = new Isotope("#grid", {
            itemSelector: ".grid-item",
            isFitWidth: true,
            percentPosition: true,
            masonry: {
                columnWidth: '.grid-sizer',
                gutter: 0
            }
        });
    });
}
